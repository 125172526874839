import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { Link, useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Menu = ({ data }) => {
  const { wp, wpUser } = useStaticQuery(
    graphql`
      query {
        wp {
          generalSettings {
            title
            description
          }
        }
        wpUser {
          twitter: name
        }
      }
    `
  )

  //const metaDescription = description || wp.generalSettings?.description
  //const defaultTitle = wp.generalSettings?.title

  return (
    <div class="menucontainer navbar">
    </div>
  )
}

export default Menu
