import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import {Helmet} from "react-helmet";
import "../css/@wordpress/block-library/build-style/style.css"
import "../css/@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Menu from "../components/menu"

const BlogPageTemplate = ({ data: { menuItems, post } }) => {
  const featuredImage = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }
  return (
    <Layout>
    <Helmet>
    </Helmet>
      <Seo title={post.title} description={post.excerpt} />
          <header class="masthead">
              <div class="container position-relative px-4 px-lg-5">
                  <div class="row gx-4 gx-lg-5 justify-content-center">
                      <div class="col-md-10 col-lg-8 col-xl-7">
                          <div class="site-heading">
                          <div><Link to="/">
                          <img className="logos" alt="Blog de Receptes" title="Blog de Receptes" src={'/logo.png'} alt="Logo" />
                          </Link></div>
                          <p>Yoga Mats: Esterillas de yoga</p>
                          </div>
                      </div>
                  </div>
              </div>
          </header>
          <Menu />
      <article
        className="global-wrapper blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
      <h1 className="entry-title" itemProp="headline">{parse(post.title)}</h1>

        {!!post.content && (
          <section itemProp="articleBody">{parse(post.content)}</section>
        )}
      </article>
    </Layout>
  )
}
export default BlogPageTemplate

export const pageQuery = graphql`
  query BlogPageById(
    $id: String!
  ) {
    post: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  }
`
